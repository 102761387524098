import React, { useRef } from "react";
import CopyButton from "../CopyButton";
import { Terminal } from "lucide-react";
import { useTheme } from "../../context/ThemeContext";
import "highlight.js/styles/github.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/monokai-sublime.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/atom-one-dark.css"; // 导入代码高亮的CSS样式
import "highlight.js/styles/atom-one-light.css"; // 导入代码高亮的CSS样式
import "katex/dist/katex.min.css"; // KaTeX 样式

export default function CodeBlock(props: {
  children: any;
  node?: any;
  simple?: boolean;
  arg?: any;
}) {
  const { children, node, simple = false, arg } = props;
  const { theme } = useTheme();
  const codeRef = useRef<any>(null);
  const copyButtonRef = useRef<any>(null);
  return !simple ? (
    <div
      className={`not-prose rounded-md  border-2  ${
        theme === "dark" ? "border-black" : "border-gray"
      }`}
    >
      <div
        className={`flex h-12 items-center justify-between px-4 ${
          theme == "light" ? " bg-zinc-100 dark:bg-zinc-900" : "bg-zinc-900"
        }`}
      >
        <div className="flex items-center gap-2">
          <Terminal size={16} />
          <p
            className={`text-sm ${
              theme == "light" ? "text-zinc-600 dark:text-zinc-400" : ""
            }`}
          >
            {node?.data?.meta}
          </p>
        </div>
        <CopyButton
          ref={copyButtonRef}
          onClick={() => {
            if (codeRef.current) {
              copyButtonRef.current.copy(codeRef.current.innerText);
            }
          }}
        />
      </div>
      <div className={`overflow-x-auto`}>
        <div
          ref={codeRef}
          className="p-4"
          style={{
            backgroundColor: theme == "light" ? "#D2D2D2" : "#282828",
          }}
        >
          {children}
        </div>
      </div>
    </div>
  ) : (
    <code
      {...arg}
      className={`not-prose rounded  px-1 ${
        theme == "light" ? " bg-gray-100 dark:bg-zinc-900" : "bg-zinc-900"
      }`}
    >
      {children}
    </code>
  );
}
