import React, { useState } from "react";
import styles from "../../styles/chat.module.scss";
import TypingEffect from "../TypingEffect";
import {
  LoadingOutlined,
} from "@ant-design/icons";
import LoadingIcon from "../Icon/LoadingIcon";

export default function ChatItem(props: {
  itemkey: number | string;
  item: any;
  cancleTag: boolean;
  goToBottom: () => void;
  handleCopy: any;
}) {
  const { itemkey, item, cancleTag, goToBottom, handleCopy } = props;

  const ItemChat = React.useMemo(() => {
    return (
      <div key={item.uuid ? item.uuid : itemkey}>
        {item.user_type == "user" && item.text && (
          <div
            key={item.uuid ? item.uuid : itemkey}
            className={styles.chatpdfRow}
          >
            <div className={styles.chatpdfAsk}>
              {item.file_name && (
                <div className={styles.fileBox}>
                  <div className={styles.file}>
                    <div className={styles.fileBg} />
                    <span className={styles.fileName} title={item.file_name}>
                      {item.file_name}
                    </span>
                  </div>
                </div>
              )}
              <div className={styles.askBox}>
                <div className={styles.chatAskPdfContent}>
                  <pre>{item.text}</pre>
                </div>
                <div className={styles.chatpdfIcon}></div>
              </div>
            </div>
          </div>
        )}
        {item.user_type == "assistant" && (
          <div
            key={item.uuid ? item.uuid : itemkey}
            className={styles.chatpdfRow}
          >
            <div className={styles.chatpdfIcon}></div>

            {item.text != "当前对话不存在" &&
              item.text != "订阅已到期" &&
              item.text !=
                "您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅" && (
                <div className={styles.chatpdfContent}>
                  {item.text === "loading" &&
                    (!cancleTag ? (
                      <div className={styles.loadingContainer}>
                        <LoadingIcon size={30} />
                        <p className={styles.thinkingText}>Eagle正在思考中</p>
                      </div>
                    ) : (
                      <p style={{ color: "red" }}>已取消</p>
                    ))}
                  {item.text !== "loading" && (
                    <TypingEffect
                      stop={item.pending}
                      pending={item.pending}
                      goToBottom={goToBottom}
                      text={item.text}
                      index={item.uuid ? item.uuid : itemkey}
                    />
                  )}
                  {!item.pending &&
                    item.text !== "loading" &&
                    item.text !== "" && (
                      <div className={styles.toolBtns}>
                        <div
                          className={styles.copy}
                          title="复制"
                          onClick={() => handleCopy(item.text)}
                        >
                          <div className={styles.copyIcon} />
                        </div>
                      </div>
                    )}
                  {item.pending &&
                    item.text !== "loading" &&
                    item.text !== "" && (
                      <div className={styles.toolBtns}>
                        <LoadingOutlined /> 正在回答中...
                      </div>
                    )}
                </div>
              )}
            {(item.text === "当前对话不存在" ||
              item.text === "订阅已到期" ||
              item.text ===
                "您的今日试用对话机会已用完，请明天继续使用，或者进行会员订阅") && (
              <div className={styles.chatpdfContent}>
                <p style={{ color: "red" }}>{item.text}</p>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }, [cancleTag, item, itemkey]);
  return ItemChat;
}
