import copy from "copy-to-clipboard";
import { Copy, Check } from "lucide-react";
import { forwardRef, useImperativeHandle, useState } from "react";

const CopyButton = (props: { onClick: () => void }, ref: any) => {
  const [copied, setCopited] = useState(false);

  useImperativeHandle(ref, () => {
    return {
      copy: (text: string) => onCopy(text),
    };
  });
  const onCopy = async (text: string) => {
    try {
      setCopited(true);
      copy(text);
      setTimeout(() => {
        setCopited(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <button
      onClick={props.onClick}
      className="inline-flex rounded-md p-2 hover:bg-zinc-200 dark:hover:bg-zinc-800"
    >
      <Copy
        size={16}
        className={`transition-all
        ${copied ? "scale-0" : "scale-100"}
      `}
      />
      <Check
        size={16}
        className={`absolute transition-all ${
          copied ? "scale-100" : "scale-0"
        }`}
      />
    </button>
  );
};

export default forwardRef(CopyButton);
