import styles from "../styles/chat.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import Router, { useRouter } from "next/router";
import http from "../http";
import Setting from "../components/Setting";
import { useUserInfo } from "../context/ThemeContext";
import { ShowMessage } from "../components/ShowMessage/ShowMessage";
import LeftPannel from "../components/common/LeftPannel";
import LoginBox from "../components/common/LoginBox";
import ChatpdfBox from "../components/common/ChatpdfBox";
import { TreeData } from "../components/tree/CodeTree";
import Login from "../components/common/login";
import { Spin } from "antd";
import LoadingIcon from "../components/Icon/LoadingIcon";
import Guide from "../components/Guide/Guide";
import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import { accept } from "../components/UpLoad/UpLoad";
import { CodeDataType } from "../hook/useManualServerSentEvents";
export default function Index() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [settingShow, setSettingShow] = useState(false);
  const { userInfo, toggleUserInfo } = useUserInfo();
  const loginBox = useRef<any>();
  const login = useRef<any>();
  const [visit_page, setVisit_page] = useState(0);
  const chatBox = useRef<any>();
  const leftPannel = useRef<any>();
  const [treeData, setTreeData] = useState<TreeData[]>([]);
  const [codeData, setCodeData] = useState<CodeDataType>({
    branch_name: "",
    repo_full_name: "",
    type: "",
  });
  const [isoutputing, setIsoutputing] = useState<boolean>(false);
  const [dragOver, setDragOver] = useState(false);
  const dragCounter = useRef(0);
  const guide = useRef<any>();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      // 页面刷新之后取消threadId的连接
      const ids = JSON.parse(localStorage.getItem("threadIds") || "[]");
      ids.forEach(async (id: string) => {
        await cancleChat(id);
      });
      localStorage.setItem("threadIds", JSON.stringify([]));
      getInfo();
    } else {
      openNotification();
      // guide.current?.show();
    }
  }, []);

  useEffect(() => {
    //debugger
    if (router.isReady) {
      const token = localStorage.getItem("token");
      // 路由参数已准备好，可以进行操作
      router.query.github_id && setSettingShow(true);
      router.query?.bd_vid &&
        sessionStorage.setItem(
          "bd_vid",
          router.query?.bd_vid?.toString() || ""
        );
      router.query?.utm_source &&
        sessionStorage.setItem(
          "utm_source",
          router.query?.utm_source.toString()
        );

      if (userInfo.phone_number && token) {
        if (!router.query.thread_id) {
          chatBox.current?.createThread();
        } else {
          chatBox.current?.getChatLog(router.query.thread_id);
        }
      }
    }
  }, [router.isReady, userInfo]);

  const getInfo = useCallback(() => {
    setIsLoading(true);
    http
      .post(`/api/v1/account/info`)
      .then((data: any) => {
        setIsLoading(false);
        if (data.status) {
          if (data.data.subscription_expired) {
            const [datePart] = data.data.subscription_expired.split(" ");
            data.data.subscription_expired = datePart;
          }
          toggleUserInfo(data.data);
          login.current && login.current?.close();
        } else {
          ShowMessage.error(data.message);
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          openNotification();
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const chatItemClick = (thread_id: string) => {
    setSettingShow(false);
    chatBox.current?.getChatLog(thread_id);
  };

  const createNewChat = async () => {
    const token = localStorage.getItem("token");
    if (!userInfo.phone_number || !token) {
      login.current?.show();
      return;
    }

    setSettingShow(false);
    setIsLoading(true);
    chatBox.current?.createNewChat();
  };

  const cancleChat = (thread_id: string) => {
    //取消与后端的聊天
    return new Promise((resolve, reject) => {
      http
        .post("api/v1/chat/cancel", { thread_id: thread_id })
        .then((data: any) => {
          if (data.status) {
            resolve(true);
          } else {
            resolve(false);
            ShowMessage.error(data.message);
          }
        })
        .finally(() => {
          setIsoutputing(false);
        });
    });
  };

  const handleSetting = () => {
    setSettingShow(!settingShow);
    setVisit_page(0);
  };
  const gotoPay = () => {
    setSettingShow(!settingShow);
    setVisit_page(1);
  };
  const openNotification = () => {
    setSettingShow(false);
    loginBox.current?.showLogin();
  };
  const loginSucccess = () => {
    getInfo();
  };
  const chat = React.useMemo(
    () => (
      <ChatpdfBox
        visible={!settingShow}
        reLoadCancleChat={cancleChat}
        cancleChat={cancleChat}
        getInfo={getInfo}
        setLoading={(isLoading) => {
          setIsLoading(isLoading);
        }}
        setSettingShow={(is) => {
          setSettingShow(is);
        }}
        setData={(treeData, codeData, isoutputing) => {
          setTreeData(treeData);
          setCodeData(codeData);
          setIsoutputing(isoutputing);
        }}
        gotoLogin={() => {
          login.current?.show();
        }}
        ref={chatBox}
        updateMenu={() => {
          leftPannel.current && leftPannel.current?.getChatList();
        }}
      />
    ),
    [getInfo, settingShow]
  );
  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (Array.from(e.dataTransfer.types).includes("Files")) {
      if (!dragOver && !settingShow) {
        setDragOver(true);
      }
    }
  };
  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    // 检查是否实际有文件拖离
    const { target, relatedTarget } = e;
    if (!relatedTarget || !(relatedTarget as HTMLElement).closest("#chatpdf")) {
      setDragOver(false);
    }
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (settingShow) {
      return;
    }
    setDragOver(false);
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      if (chatBox.current) {
        chatBox.current?.uploadFile(files);
      }
    }
  };
  return (
    <>
      <div
        key={userInfo.phone_number}
        id="chatpdf"
        className={styles.chatpdf}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <LeftPannel
          treeData={treeData}
          isoutputing={isoutputing}
          codeData={codeData}
          createNewChat={createNewChat}
          chatItemClick={chatItemClick}
          settingClick={handleSetting}
          gotoPay={gotoPay}
          logOut={() => {
            setTimeout(() => {
              openNotification();
            }, 100);
          }}
          handleLogin={() => {
            login.current?.show();
          }}
          setLoading={(
            loading: boolean | ((prevState: boolean) => boolean)
          ) => {
            setIsLoading(loading);
          }}
          ref={leftPannel}
        ></LeftPannel>
        {chat}
        <Setting
          visible={settingShow}
          visit_page={visit_page}
          getInfo={getInfo}
          userInfo={userInfo}
          setIsLoading={setIsLoading}
          oncancel={() => {
            setSettingShow(false);
          }}
        ></Setting>
        {dragOver && (
          <div className={styles.dragover}>
            <UploadOutlined style={{ fontSize: 36 }} />
            <div className={styles.dragover_text}>在此处拖放文件</div>
            <div className={styles.dragover_accept}>
              支持文件类型：{accept.map((ext) => ext.slice(1)).join(", ")}
            </div>
          </div>
        )}
      </div>
      <LoginBox
        ref={loginBox}
        gotoLogin={(openId) => {
          login.current?.show(openId);
        }}
        loginSuccess={loginSucccess}
      />
      <Login ref={login} loginSucccess={loginSucccess}></Login>
      <Guide
        ref={guide}
        onCancel={() => {
          setTimeout(() => {
            openNotification();
          }, 100);
        }}
      />
      <Spin
        spinning={isLoading}
        indicator={<LoadingIcon size={48} />}
        fullscreen
      />
    </>
  );
}
