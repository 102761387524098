import { LoadingOutlined, RightOutlined } from "@ant-design/icons";
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import styles from "../../styles/chat.module.scss";
import { ShowMessage } from "../ShowMessage/ShowMessage";
import copy from "copy-to-clipboard";
import ChatItem from "./chatItem";

function ChatpdfLine(
  props: {
    chatContent: any;
    cancleTag: boolean;
  },
  ref: any
) {
  const { chatContent, cancleTag } = props;
  const chatContainerRef = useRef({} as any);
  const autoScroll = useRef(true as boolean);
  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;
    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 100;
    // setAutoScroll(isAtBottom);
    autoScroll.current = isAtBottom;
  }, []);
  useEffect(() => {
    if (chatContainerRef.current && autoScroll.current) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current.scrollTop = scrollHeight - clientHeight;
    }
  }, [chatContent, autoScroll]); // 在 chatContent 或 autoScroll 变化时触发

  const goToBottom = (allways: boolean = false) => {
    if (chatContainerRef.current && (allways || autoScroll.current)) {
      const { scrollHeight, clientHeight } = chatContainerRef.current;
      chatContainerRef.current?.scrollTo({
        top: scrollHeight + 999, // 滚动到的垂直位置
        left: 0, // 滚动到的水平位置
        behavior: "smooth", // 平滑滚动
      });
    }
  };
  const handleCopy = (text: string) => {
    copy(text);
    ShowMessage.success("复制成功");
  };
  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);

      return () => {
        chatContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);
  useImperativeHandle(ref, () => {
    return {
      goToBottom,
    };
  });
  const ChatLine = React.useMemo(() => {
    return (
      <div
        className={styles.chatpdfLine}
        ref={chatContainerRef}
        onScroll={handleScroll}
      >
        {chatContent.map((item: any, index: number) => {
          return (
            <div key={index}>
              <ChatItem
                itemkey={index}
                item={item}
                cancleTag={cancleTag}
                handleCopy={handleCopy}
                goToBottom={goToBottom}
              />
            </div>
          );
        })}
      </div>
    );
  }, [cancleTag, chatContent, handleScroll]);
  return ChatLine;
}
export default React.forwardRef(ChatpdfLine);
